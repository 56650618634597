import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import ArticlePage from "./abstract-article"
import { group } from "../articleTypes"

const GroupPage = ({ data, ...props }) => {
  return (
    <ArticlePage
      {...props}
      article={data.group}
      articleType={group}
      articleTypeTag="group"
      articleTypePath="/groups"
    />
  )
}
GroupPage.propTypes = {
  data: PropTypes.any,
}

export const query = graphql`
  query($slug: String!) {
    group: markdownRemark(
      fields: { contentKind: { eq: "group" }, slug: { eq: $slug } }
    ) {
      ...TinaRemark
      ...ArticlePageFragment
    }
  }
`

export default GroupPage
